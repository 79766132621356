import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';
import {
  APP_ROUTES,
  IntermediateScreen,
  TEST_TASK_STATUS,
  TestTaskType,
} from '../constants';
import {
  getUserMissingRequirementCatagories,
  isShowEngagementBasedOnCountry,
  isSkipCityBasedOnCountry,
  isSkipEnglishLevelBasedOnCountry,
  isSkipSeniorityBasedOnExperience,
  isSkipSpecializationBasedOnUserPosition,
  isStatusComplete,
  isStatusFailed,
  isUserPositionPartial,
  shouldSkipOffice,
} from '../utils';
import { setUserRoute, userRouteSelector } from '../redux/userRoute';
import { usePositionData } from './usePositionData';
import { useUserVideoInterview } from './useUserVideoInterview';
import { useIsDisqualified } from './useIsDisqualified';
import { UserVideoInterviewStatus } from '../types/userVideoInterviewData';
import { useIsStandalonePage } from './useIsStandalonePage';
import { useUserTestTasks } from './useUserTestTasks';
import { useKnowledgeQuiz } from './useKnowledgeQuiz';
import { useLPLocation } from './useLPLocation';
import { useIsIndependentVIPage } from './useIsIndependentVIPage';
import { useUserMeetings } from './useUserMeetings';
import { isPast } from 'date-fns';
import isNumber from 'lodash/isNumber';
import { JobOpeningCrucialRequirementCategory } from 'src/types';

export interface ILocationState {
  pathname: string;
  search: string;
  hash: string;
  state: any;
}

export const useUserRedirection = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location: ILocationState = useLocation();
  const isStandalonePage = useIsStandalonePage();

  const userApiStatus = useAppSelector(userSelectors.getUserApiStatus);
  const userData = useAppSelector(userSelectors.getUserData);
  const currentRoute = useAppSelector(userRouteSelector.getUserRoute);

  // Profile data stage
  const positionData = usePositionData();
  const { suggestedCountries, officeLocations } = useLPLocation();

  const isSkipCity = isSkipCityBasedOnCountry(
    userData?.country,
    suggestedCountries,
  );
  const isSkipOffice = shouldSkipOffice(
    userData?.city,
    userData?.officeLocation,
    officeLocations,
  );
  const isSkipEnglishLevel = isSkipEnglishLevelBasedOnCountry(
    userData?.country,
  );
  const isSkipSeniority = isSkipSeniorityBasedOnExperience(
    userData?.yearsOfExperience,
  );
  const isShowEngagement = isShowEngagementBasedOnCountry(userData?.country);
  const isPartialUserPosition = isUserPositionPartial(positionData);
  const isSkipSpecialization =
    isSkipSpecializationBasedOnUserPosition(positionData);
  const { isDisqualified, isNotSupportedByOpenPositions } = useIsDisqualified();

  // Video Interview stage
  const videoInterviewData = useUserVideoInterview();
  const isIndependentVIPage = useIsIndependentVIPage();

  const hasAvailableVideoInterview =
    !!videoInterviewData.availableVideoInterview?.length;
  const isVIInProgress =
    videoInterviewData.userVideoInterview?.status ===
    UserVideoInterviewStatus.STARTED;
  const isVISkipped =
    videoInterviewData.userVideoInterview?.status ===
    UserVideoInterviewStatus.SKIPPED;

  // Knowledge Quiz stage
  const { hasKnowledgeQuizToDo, hasStartedKnowledgeQuiz } = useKnowledgeQuiz();

  // Test Task stage
  const { currentTestTask, availableTestTasks, passedTestTasks, doneTestTask } =
    useUserTestTasks();

  const hasTestTaskToSelect = !!availableTestTasks.some(
    (tt) => tt.status === TEST_TASK_STATUS.TODO,
  );
  const hasPassedTestTask = !!passedTestTasks?.length;
  const hasDoneTestTask = !!doneTestTask;
  const currentTestTaskType = currentTestTask?.testTaskType;

  // Intro Call Stage
  const { activeIntroCall } = useUserMeetings();

  const hasIntroCall = !!activeIntroCall && !activeIntroCall.canceled;
  const hasPassedIntroCall =
    !!activeIntroCall &&
    !activeIntroCall.canceled &&
    isPast(new Date(activeIntroCall.scheduledDate));

  // Technical Inteview Stage
  const { activeTechnicalInterview } = useUserMeetings();

  const hasTechnicalInterview =
    !!activeTechnicalInterview && !activeTechnicalInterview.canceled;

  const getUserRedirectionRoute = () => {
    if (isStandalonePage || !isStatusComplete(userApiStatus) || !userData)
      return;

    if (
      isStatusFailed(userApiStatus) &&
      location.pathname !== '/' &&
      !location.pathname.includes(APP_ROUTES.EMAIL)
    ) {
      return APP_ROUTES.HOME;
    }

    if (isIndependentVIPage) {
      return location.pathname;
    }

    if (userData.intermediateScreen) {
      if (userData.intermediateScreen === IntermediateScreen.LongTimeNoSee)
        return APP_ROUTES.LONG_TIME_NO_SEE;

      if (
        userData.intermediateScreen ===
        IntermediateScreen.VideoInterviewFinished
      )
        return APP_ROUTES.VIDEO_INTERVIEW_FINISHED;

      if (userData.intermediateScreen === IntermediateScreen.TestTaskSent)
        return APP_ROUTES.INTERNAL_TEST_TASK_SENT;

      if (userData.intermediateScreen === IntermediateScreen.FirstStepIsDone)
        return APP_ROUTES.FIRST_STEP_IS_DONE;
    }

    if (!userData.profileSubmittedAt) {
      if (!userData.firstName || !userData.lastName) return APP_ROUTES.NAME;
      if (!userData.country) return APP_ROUTES.COUNTRY;
      if (!userData.city && !isSkipCity) return APP_ROUTES.CITY;
      if (!userData.officeLocation && !isSkipOffice) return APP_ROUTES.OFFICE;
      if (!userData.englishLevel && !isSkipEnglishLevel)
        return APP_ROUTES.ENGLISH_LEVEL;
      if (userData.specialization && isPartialUserPosition)
        return `/${APP_ROUTES.TECH_SKILLS}/${APP_ROUTES.PRIMARY_SKILLS}`;
      if (!userData.specialization && !isSkipSpecialization)
        return APP_ROUTES.TECH_SKILLS;
      if (!isNumber(userData.yearsOfExperience)) return APP_ROUTES.EXPERIENCE;
      if (!userData.seniority && !isSkipSeniority) return APP_ROUTES.SENIORITY;
      if (!isNumber(userData.compensationExpectations))
        return APP_ROUTES.COMPENSATION;
      if (!isNumber(userData.noticePeriod)) return APP_ROUTES.NOTICE_PERIOD;
      if (!userData.originalCvFileName) return APP_ROUTES.PROFESSIONAL_IDENTITY;
      return APP_ROUTES.PROFILE_REVIEW;
    }

    const missingCrucialCategories =
      getUserMissingRequirementCatagories(userData);

    if (
      missingCrucialCategories.length &&
      !userData.crucialRequirementsSubmittedAt &&
      (isNotSupportedByOpenPositions || !isDisqualified)
    ) {
      if (
        missingCrucialCategories.includes(
          JobOpeningCrucialRequirementCategory.DomainKnowledge,
        ) ||
        missingCrucialCategories.includes(
          JobOpeningCrucialRequirementCategory.Technology,
        )
      )
        return APP_ROUTES.SKILLS_REQUIREMENTS;
      if (
        missingCrucialCategories.includes(
          JobOpeningCrucialRequirementCategory.Education,
        )
      )
        return APP_ROUTES.EDUCATION_REQUIREMENTS;
      if (
        missingCrucialCategories.includes(
          JobOpeningCrucialRequirementCategory.Visa,
        )
      )
        return APP_ROUTES.VISA_REQUIREMENTS;
    }

    if (isVIInProgress) {
      return APP_ROUTES.RECORD_VIDEO;
    }

    if (hasStartedKnowledgeQuiz) {
      return APP_ROUTES.KNOWLEDGE_QUIZ;
    }

    if (currentTestTaskType === TestTaskType.INTERNAL) {
      return APP_ROUTES.INTERNAL_TEST_TASK;
    }

    if (currentTestTaskType === TestTaskType.QUIZ) {
      return `${APP_ROUTES.QUIZ_TEST_TASK}`;
    }

    if (currentTestTaskType === TestTaskType.CODILITY) {
      return `${APP_ROUTES.CODILITY_TEST_TASK}`;
    }

    if (currentTestTaskType === TestTaskType.CUSTOM) {
      return `${APP_ROUTES.APPLICATION_SUCCESS}`;
    }

    if (
      hasKnowledgeQuizToDo &&
      userData.profileSubmittedAt &&
      !isDisqualified
    ) {
      return APP_ROUTES.KNOWLEDGE_QUIZ;
    }

    if (
      hasTestTaskToSelect &&
      userData.profileSubmittedAt &&
      !isDisqualified &&
      !hasPassedTestTask &&
      !hasDoneTestTask &&
      !hasIntroCall
    ) {
      return APP_ROUTES.TEST_TASK_SELECTION;
    }

    if (hasDoneTestTask) {
      return APP_ROUTES.INTERNAL_TEST_TASK;
    }

    if (
      hasAvailableVideoInterview &&
      userData.profileSubmittedAt &&
      !isVISkipped &&
      !isDisqualified
    ) {
      return APP_ROUTES.VIDEO_INTERVIEW_SELECTION;
    }

    if (isDisqualified) {
      return APP_ROUTES.APPLICATION_SUCCESS;
    }

    if (hasTechnicalInterview) {
      return APP_ROUTES.TECHNICAL_INTERVIEW;
    }

    if (hasPassedIntroCall) {
      return APP_ROUTES.INTRO_CALL;
    }

    return APP_ROUTES.APPLICATION_SUCCESS;
  };

  useEffect(() => {
    const userRedirectionRoute = getUserRedirectionRoute();
    const disableNavigation =
      userRedirectionRoute === APP_ROUTES.QUIZ_TEST_TASK &&
      currentRoute === APP_ROUTES.QUIZ_TEST_TASK;
    if (userRedirectionRoute && !disableNavigation) {
      dispatch(setUserRoute(userRedirectionRoute));
      navigate(userRedirectionRoute);
    }
  }, [
    userApiStatus,
    userData,
    isStandalonePage,
    currentRoute,
    isSkipCity,
    isSkipEnglishLevel,
    isPartialUserPosition,
    isSkipSpecialization,
    isSkipOffice,
    isSkipSeniority,
    isShowEngagement,
    isDisqualified,
    isNotSupportedByOpenPositions,
    isIndependentVIPage,
    hasAvailableVideoInterview,
    isVIInProgress,
    isVISkipped,
    hasKnowledgeQuizToDo,
    hasStartedKnowledgeQuiz,
    hasTestTaskToSelect,
    hasPassedTestTask,
    hasDoneTestTask,
    currentTestTaskType,
    hasIntroCall,
    hasPassedIntroCall,
    hasTechnicalInterview,
  ]);
};
